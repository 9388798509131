// Let's import our styles
import '../scss/main.scss';
import Typed from 'typed.js';

// Our JS
var typed = new Typed('.theCrunchlineAnimated i', {
    strings: ['an emergency.', 'a crisis.', 'danger.', 'a crunch.^4000'],
    typeSpeed: 100,
    backDelay: 1000,
    loop: true
});
